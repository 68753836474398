import { render, staticRenderFns } from "./Manualorder.vue?vue&type=template&id=4704b850&scoped=true"
import script from "./Manualorder.vue?vue&type=script&lang=js"
export * from "./Manualorder.vue?vue&type=script&lang=js"
import style0 from "./Manualorder.vue?vue&type=style&index=0&id=4704b850&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4704b850",
  null
  
)

export default component.exports